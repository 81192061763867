<template>
    <accordion>
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How can I verify my account?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>To verify your account, sign up and upload the required identity documents such as a government-issued ID, passport, or driver’s license. Address verification may also be required, using documents like a utility bill or bank statement.</p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">Can I have more than one account?</button>
            </template>
            <template v-slot:accordion-content>
                <p>No, it is prohibited to have more than one account on our platform. If multiple accounts are detected, they will be temporarily disabled until you select one to keep active while the others remain permanently blocked.</p>
               
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">What should I do if I forget my password?</button>
            </template>
            <template v-slot:accordion-content>
                <p> If you forget your password, you can reset it using our Password Recovery service. Simply follow the instructions sent to your registered email address.</p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">What are the core values of Temo Direct?</button>
            </template>
            <template v-slot:accordion-content>
                <p><strong>Temo</strong> is always looking for talented <a href="#">information</a> security and IT risk management professionals who are dedicated, hard working and looking for a challenge. If you are interested in employment with <strong>Temo</strong>, a company who values you and your family, visit our careers page.</p>
            </template>
        </accordion-item>
        
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">What is the fastest way to buy Bitcoin?</button>
            </template>
            <template v-slot:accordion-content>
                <p>The fastest way to buy Bitcoin on PayBuyGo is using a Visa or MasterCard credit/debit card. The first transaction takes up to 15 minutes, while subsequent transactions are usually completed within 2 minutes.</p>
             
            </template>
        </accordion-item>
        
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">Can I cancel my order after the payment is sent?</button>
            </template>
            <template v-slot:accordion-content>
                <p>No, once the payment has been sent, the order is considered complete and cannot be canceled.</p>
            </template>
        </accordion-item>

         <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">Are there limits on how much Bitcoin I can buy?</button>
            </template>
            <template v-slot:accordion-content>
                <p>Yes, for Visa/MasterCard transactions, the limit is $20,000 per week or $50,000 per month. Bank transfers have higher limits, such as up to €100,000 per week.</p>
            </template>
        </accordion-item>

         <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">How long does the verification process take?</button>
            </template>
            <template v-slot:accordion-content>
                <p>The verification process typically takes about 5 minutes but can take up to 24 hours in some cases.</p>
            </template>
        </accordion-item>

         <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">What documents are required for identity verification?</button>
            </template>
            <template v-slot:accordion-content>
                <p>Acceptable documents include a government-issued passport, identity card, or driver’s license, and a selfie with the ID. For address verification, a utility bill or bank statement not older than six months is required.</p>
            </template>
        </accordion-item>
        
         <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">How do you ensure the security of my documents?</button>
            </template>
            <template v-slot:accordion-content>
                <p>Uploaded documents are stored on secure servers with no public internet access. We comply with European Union Data Protection Laws and other relevant regulations to protect your information.
</p>
            </template>
        </accordion-item>
    </accordion>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'AccordionContant',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>