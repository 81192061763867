<template>
    <div class="earn-money-area pt-100 pb-70 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <h2>Earn Money With Temo</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="earn-money-list">
                        <h3>Campaign Tracking</h3>
                        <ul>
                            <li><i class='bx bxs-pie-chart'></i> Get direct access to your campaign’s performance data</li>
                            <li><i class='bx bx-cog'></i> Test your campaigns with custom landing page tools and deep links</li>
                            <li><i class='bx bxs-badge-check'></i> Set up 5+ customizable performance reports</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="earn-money-list">
                        <h3>Convenient Payments</h3>
                        <ul>
                            <li><i class='bx bx-support'></i> Get compensated in your local currency, no matter where you live</li>
                            <li><i class='bx bxs-discount'></i> Receive 50% of your referrees’ trading fees for the first 3 months</li>
                            <li><i class='bx bx-credit-card-front'></i> Get paid electronically via PayPal or your bank account</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EarnMoney'
}
</script>