<template>
    <div class="wallet-area ptb-100">
        <div class="container">
            <div class="wallet-tabs">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="tabs__container">
                            <div 
                                class="tabs__list"
                                ref='tabsList'
                            >
                                <div 
                                    class="tabs__list-tab"
                                    v-for="tab in tabs"
                                    v-bind:class="{ 'active': activeTab == tab.id }"
                                    :key="tab.id"
                                >
                                    <div class="box">
                                        <h3>{{tab.heading}}</h3>
                                        <p>{{tab.description}}</p>
                                        <ul class="features-list">
                                            <li
                                                v-for="list in tab.lists"
                                                :key="list.id"
                                            >
                                                <i class='bx bx-check'></i> 
                                                {{list.list}}
                                            </li>
                                        </ul>
                                        <router-link to="/login-register" class="default-btn">
                                            <i class='bx bxs-user'></i> 
                                            Learn More
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="tabs">
                            <ul class="tabs__header">
                                <li 
                                    class="tabs__header-item" 
                                    v-for="tab in tabs" 
                                    v-on:click="selectTab (tab.id)" 
                                    v-bind:class="{ 'active': activeTab == tab.id }"
                                    :key="tab.id"
                                >
                                    <i :class="tab.icon"></i>
                                    {{tab.title}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WalletTab',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-shield-quarter',
                    title: 'Security',
                    heading: 'Best in Class Security',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
                    lists: [
                        {
                            id: 1,
                            list: 'Best in Class Security'
                        },
                    ],
                },
                {
                    id: 2,
                    icon: 'bx bx-transfer',
                    title: 'Send & Receive',
                    heading: 'Send & Receive Instantly',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
                    lists: [
                        {
                            id: 1,
                            list: 'Lowest fees'
                        },
                    ],
                },
                {
                    id: 3,
                    icon: 'bx bx-dollar',
                    title: 'Buy, Sell, & Exchange',
                    heading: 'Exchange Crypto to Crypto',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
                    lists: [
                        {
                            id: 1,
                            list: 'Exchange crypto-to-crypto instantly'
                        },
                    ],
                },
                {
                    id: 4,
                    icon: 'bx bx-globe',
                    title: 'Access Anywhere',
                    heading: 'Access Your Wallet Anywhere',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
                    lists: [
                        {
                            id: 1,
                            list: 'iOS, Android, Web'
                        },
                    ],
                }
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>