<template>
    <div class="main-banner-area">
        <div class="container">
            <div class="row align-items-center m-0">
                <div class="col-xl-6 col-lg-6 col-md-12 p-0">
                    <div class="main-banner-content">
                        <h1>Buy Cryptocurrency <br> Easy, Fast and Secure!</h1>
                        <p>First transaction — no PayBuyGo commission!  <br>Enjoy world-class commission rates! Buy the most popular coins in seconds!<br> Now available in over 190 countries.</p>
                        <router-link to="www.google.com" class="default-btn">
                            <i class='bx bxs-user'></i> 
                            Buy Coins with a few clicks!
                        </router-link>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 p-0">
                    <div class="main-banner-image">
                        <img src="../../assets/img/banner/banner-img1.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="main-banner-box">
                <MainBannerBox />
            </div>
        
        </div>
        <div class="shape1"><img src="../../assets/img/shape/shape1.png" alt="image"></div>
        <div class="shape2"><img src="../../assets/img/shape/shape2.png" alt="image"></div>
        <div class="shape5"><img src="../../assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>

    </div>
</template>

<script>
import MainBannerBox from './MainBannerBox'

export default {
    name: 'MainBanner',
    components: {
        MainBannerBox,
    },
}
</script>