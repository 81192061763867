<template>
    <div class="fedback-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Trusted by Thousands of Users</h2>
                <p>Having happy clients and users, encourage us to provide most advanced solution with technology.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-feedback-box">
                        <div class="rating">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                        </div>
                        <p>I couldn't be happier with the PayBuyGo platform. The convenience of buying crypto with my credit card from anywher is incredible. The transactions are super fast, and the customer support is very responsive and helpful. It's made investing in crypto so much easier for me!</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/user/user1.jpg" alt="image">
                                <div class="title">
                                    <h3>David Malan</h3>
                                    <span>CTO of an Ecommerce Site</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-feedback-box">
                        <div class="rating">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                        </div>
                        <p>This platform by PayBuyGo is a lifesaver! I love that I can purchase crypto in seconds using my credit card, no matter where I am. The process is straightforward, and the interface is intuitive. Plus, their support team is always ready to assist with any questions I have.</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/user/user2.jpg" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>Partner</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-feedback-box">
                        <div class="rating">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                        </div>
                        <p>Absolutely love this service! The ability to buy crypto with a credit card from virtually any country is amazing. The transactions are lightning-fast, and the platform is very user-friendly. It has significantly simplified my crypto transactions.</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/user/user3.jpg" alt="image">
                                <div class="title">
                                    <h3>Ben Stokes</h3>
                                    <span>Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feedback'
}
</script>