<template>
    <div class="account-create-process-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-8 col-lg-9 col-md-12">
                    <div class="account-create-process-content">
                        <div class="section-title">
                            <h2>Get Started in a Few Minutes</h2>
                            <p>Thanks to PayBuyGo's advanced and fast services, you can create an account in seconds and immediately purchase your desired cryptocurrency while making your first investment.</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon1.png" alt="image">
                                    </div>
                                    <h3>Create Account</h3>
                                    <p>To buy crypto with a debit or credit card, create a free account on PayBuyGo with your email and phone number. Depending on your location, payment method, and purchase amount, a quick KYC process may be required. Our support team can assist, and the process typically takes less than 2 minutes.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon2.png" alt="image">
                                    </div>
                                    <h3>Choose the Amount</h3>
                                    <p>Decide how much Bitcoin to buy by entering the amount in BTC or your local currency. The equivalent BTC amount is calculated instantly. We support 40+ fiat currencies, with more to come. The total includes all network and service fees—no hidden costs.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon3.png" alt="image">
                                    </div>
                                    <h3>You are Set!</h3>
                                    <p>Enter your credit or debit card details (card number, name, security code) to buy Bitcoin. After completing the transaction, you’ll receive your Bitcoins within minutes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-12">
                    <div class="account-create-process-image text-center">
                        <img src="../../assets/img/convert-currency.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStarted'
}
</script>