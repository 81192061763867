import { createWebHistory, createRouter } from "vue-router";

import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import TradePage from "../components/Pages/TradePage";
import TeamPage from "../components/Pages/TeamPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import BuyPage from "../components/Pages/BuyPage";
import CryptocurrencyPage from "../components/Pages/CryptocurrencyPage";
import CryptocurrencyDetailsPage from "../components/Pages/CryptocurrencyDetailsPage";
import SellPage from "../components/Pages/SellPage";
import ListingsPage from "../components/Pages/ListingsPage";
import ListingsDetailsPage from "../components/Pages/ListingsDetailsPage";
import FaqPage from "../components/Pages/FaqPage";
import GuidesPage from "../components/Pages/GuidesPage";
import WalletPage from "../components/Pages/WalletPage";
import AboutPagev from "../components/Pages/AboutPage";
import ContactPage from "../components/Pages/ContactPage";
import AffiliateProgramPage from "../components/Pages/AffiliateProgramPage";
import BlogPage from "../components/Pages/BlogPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import LoginRegisterPage from "../components/Pages/LoginRegisterPage";

const routes = [
  {
    path: "/",
    name: "HomePageOne",
    component: HomePageOne,
    meta: {
      reload: true,
    },
  },
  { path: "/home-two", name: "HomePageTwo", component: HomePageTwo },
  { path: "/home-three", name: "HomePageThree", component: HomePageThree },
  { path: "/trade", name: "TradePage", component: TradePage },
  { path: "/leadership", name: "TeamPage", component: TeamPage },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  { path: "/buy", name: "BuyPage", component: BuyPage },
  {
    path: "/cryptocurrency",
    name: "CryptocurrencyPage",
    component: CryptocurrencyPage,
  },
  {
    path: "/cryptocurrency-details",
    name: "CryptocurrencyDetailsPage",
    component: CryptocurrencyDetailsPage,
  },
  { path: "/sell", name: "SellPage", component: SellPage },
  { path: "/prices", name: "ListingsPage", component: ListingsPage },
  {
    path: "/prices-details",
    name: "ListingsDetailsPage",
    component: ListingsDetailsPage,
  },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  { path: "/guides", name: "GuidesPage", component: GuidesPage },
  { path: "/wallet", name: "WalletPage", component: WalletPage },
  { path: "/about", name: "AboutPagev", component: AboutPagev },
  { path: "/contact", name: "ContactPage", component: ContactPage },
  {
    path: "/affiliate-program",
    name: "AffiliateProgramPage",
    component: AffiliateProgramPage,
  },
  { path: "/blog", name: "BlogPage", component: BlogPage },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  {
    path: "/login-register",
    name: "LoginRegisterPage",
    component: LoginRegisterPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
