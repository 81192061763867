<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Cryptocurrency Wallet" />
        <YourOwnBank />
        <CryptoWallet />
        <WalletTab />
        <MobileApp />
        <FixedDollar />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import YourOwnBank from '../Wallet/YourOwnBank'
import CryptoWallet from '../Wallet/CryptoWallet'
import WalletTab from '../Wallet/WalletTab'
import MobileApp from '../Wallet/MobileApp'
import FixedDollar from '../Wallet/FixedDollar'
import Footer from '../Layouts/Footer'

export default {
    name: 'WalletPage',
    components: {
        Navbar,
        PageTitle,
        YourOwnBank,
        CryptoWallet,
        WalletTab,
        MobileApp,
        FixedDollar,
        Footer,
    }
}
</script>