<template>
    <div class="cta-area pt-100">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cta-content">
                        <h2>{{heading}}</h2>
                        <p>Everyhing is easy, fast and reliable! What we believe is the time to try PayBuyGo Crypto Payment Services.</p>
                        <router-link to="/login-register" class="default-btn">
                            <i class='bx bxs-user'></i> 
                            Pay via Credit Card
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="cta-image">
                        <img src="../../assets/img/man.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape15"><img src="../../assets/img/shape/shape15.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTrading',
    props: ['heading']
}
</script>