<template>
    <div class="buy-sell-cryptocurrency-area bg-image pt-70">
        <div class="container">
            <div class="section-title">
                <h3>Which Methods are available on PayBuyGo?</h3>
            </div>
            <div class="row justify-content-center">
              
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-sm-4 col-4 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon12.png" alt="image">
                                    </div>
                                    <h3>Bank Transfers</h3>
                                    <p>Ease of buying crypto with bank transfers from most countries within or outside Europe.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-4 col-4 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon13.png" alt="image">
                                    </div>
                                    <h3>Online Wallets</h3>
                                    <p>The advantage of buying crypto easily with popular e-wallets is waiting for you such as Skrill, Pix, Griopay and other global ones.</p>
                                </div>
                            </div>
                           
                            <div class="col-lg-4 col-sm-4 col-4 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon15.png" alt="image">
                                    </div>
                                    <h3>Debit/Credit Cards</h3>
                                    <p>Buy crypto in seconds with credit cards worldwide with low fees.</p>
                                </div>
                            </div>
            
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyAndSell'
}
</script>