<template>
    <div class="funfacts-area pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon8.png" class="main-icon" alt="image">
                        </div>
                        <h3>Approved Security</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon9.png" class="main-icon" alt="image">
                        </div>
                        <h3>More Than 1 Million Happy Users</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon10.png" class="main-icon" alt="image">
                        </div>
                        <h3>Thousands of Trusted Offers</h3>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div class="single-funfacts-box">
                        <div class="icon">
                            <img src="../../assets/img/icon-bg.png" alt="image">
                            <img src="../../assets/img/icon/icon11.png" class="main-icon" alt="image">
                        </div>
                        <h3>Billions in Global Volume</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>