<template>
    <div class="account-register-area ptb-100">
        <div class="container">
            <div class="account-register-content">
                <h2>We Fixed The Dollar</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                <router-link to="/login-register" class="default-btn">
                    <i class='bx bxs-user'></i> 
                    Register Now
                </router-link>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'FixedDollar'
}
</script>