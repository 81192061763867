<template>
    <div>
        <Navbar/>
        <PageTitle pageTitle="Check Out Our Entire Coin Selection!" />
        <CryptocurrencySelection />
        <Features class="bg-f9f9f9" heading="How Do We Select Our Cryptocurrencies?" />
        <StartTrading heading="Start Trading on PayBuyGo Now" />
        <Footer />
    </div>
</template>

<script>

import StartTrading from '../Cryptocurrency/StartTrading'

export default {
    name: 'CryptocurrencyPage',
    components: {

        StartTrading,
    }
}
</script>