<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="faq-accordion">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <i :class="tab.icon"></i>
                            {{tab.name}}
                        </li>
                    </ul>
                </div>
                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <AccordionContant />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionContant from '../Faq/AccordionContant'

export default {
    name: 'Faq',
    components: {
        AccordionContant
    },
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-flag',
                    name: 'Account Management, Transaction, KYC and Refund',
                    content: 'Compnay dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore meniam'
                }
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>