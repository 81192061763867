<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="d-inline-block logo">
                            <img src="../../assets/img/logo.png" alt="Image">
                        </router-link>
                        <div class="newsletter-form">
                            <p>SUBSCRIBE TO OUR NEWSLETTER</p>
                            <form data-toggle="validator" style="margin-top:20px;">
                                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                                <button type="submit">
                                    Subscribe Now 
                                    <i class='bx bx-paper-plane'></i>
                                </button>
                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                     <!--   <ul class="social-links">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank" class="facebook">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank" class="twitter">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" target="_blank" class="linkedin">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank" class="instagram">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul> --->
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Cryptocurrency</h3>
                        <ul class="services-links">
                            <li><router-link to="/buy">Buy Bitcoin</router-link></li>
                            <li><router-link to="/buy">Buy BNB</router-link></li>
                            <li><router-link to="/buy">Buy Ethereum</router-link></li>
                            <li><router-link to="/buy">Buy Ripple</router-link></li>
                            <li><router-link to="/buy">Buy Litecoin</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Resources</h3>
                        <ul class="quick-links">
                            <li><router-link to="/about">About Us</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-conditions">Terms and Conditions</router-link></li>
                            <li><router-link to="/prices">Crypto Listing</router-link></li>
                            <li><router-link to="/faq">FAQ</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>
                        <ul class="footer-contact-info">
                            <li>Address: Avolonos 1, Maria House 1075, Nicosia, Cyprus</li>
                            <li>Support Email: <a href="mailto:support@paybuygo.com">support@paybuygo.com</a></li>
                            <li>Business Email: <a href="mailto:business@paybuygo.com">business@paybuygo.com</a></li>
                            <li>Phone: <a href="tel:+447464385589">+44 7464 385589</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <p>Copyright {{currentYear}} <strong>PayBuyGo</strong>. All Rights Reserved by 
                    <a href="https://paybuygo.com/" target="_blank">Laxhan Software Ltd.</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>