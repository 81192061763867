<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="privacy-policy-content">

            <h3>Privacy Policy</h3>
                <p>PayBuyGo, operated by Laxhan Software Ltd. ("Company", "we", "us", "our"), is dedicated to safeguarding your privacy. This Privacy Policy outlines how we handle your personal information when you use our services and describes your rights and how they are protected under the law.</p>

            <h3>1. Information We Collect</h3>
            <p>We may collect and process the following types of personal data:</p>
            <ul>
                    <li>Identification Information: This includes your name, photographic ID, biometric data, and date of birth.</li>
                    <li>Contact Information: This includes your address, phone number, and email address.</li>
                    <li>Account Information: This includes your username, IP address, device and browser information, banking details, and transaction history.</li>
                    <li>Usage Data: This includes information on how you interact with our services, including website and app usage.</li>

            </ul>
               

            <h3>2. How We Use Your Information</h3>
            <p>We utilize the collected information for several purposes, including:</p>
            <ul>
                   <li>Setting up and managing your account.</li>
                    <li>Processing transactions and delivering our services.</li>
                    <li>Responding to your inquiries and providing customer support.</li>
                    <li>Analyzing site usage to improve our services.</li>
                    <li>Complying with legal and regulatory requirements.</li>
                    <li>Sending promotional materials and information about our services, with your consent.</li>

            </ul>

            <h3>3. Information Sources</h3>
            <p>We gather information from:</p>
            <ul>
                   <li>Direct interactions: Data you provide when registering or using our services.</li>
                    <li>Third-party sources: Information from social networks (e.g., Facebook, Google) and blockchain data (e.g., Bitcoin, Ethereum).</li>
                   </ul>

             <h3>4. Disclosure of Your Information</h3>
            <p>We may share your personal data with:</p>
            <ul>
                   <li>Service providers and partners processing data on our behalf.</li>
                    <li>Financial institutions and payment processors.</li>
                    <li>Regulatory and law enforcement agencies as required by law.</li>
                    <li>Third parties involved in business transactions such as mergers or acquisitions.</li>
                    </ul>

             <h3>5. Data Security</h3>
            <p>We take appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, misuse, or alteration. Access to your personal information is restricted to authorized personnel who are obligated to maintain its confidentiality.</p>
           
             <h3>6. Use of Cookies</h3>
            <p>Our website uses cookies to enhance user experience. Cookies help us analyze trends, manage the site, track user movements, and collect demographic information. You can control cookie usage through your browser settings.</p>
           
             <h3>7. Retention of Information</h3>
            <p>We retain your personal data only as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. Typically, this is for at least five years after your account is closed.</p>
            
             <h3>8. Your Rights</h3>
            <p>You have the right to access, correct, update, or delete your personal information. To exercise these rights, contact us at support@laxhan.com. A small fee may apply for access requests.</p>
           
           <h3>9. Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy occasionally. Any changes will be posted on this page and, if significant, notified to you via email. By continuing to use our services, you accept the updated Privacy Policy.</p>
            
            
             <h3>10. Contact Information</h3>
            <p>For questions or concerns regarding this Privacy Policy or our data handling practices, contact us at:</p>
            <ul>
                   <li><strong>Phone Number</strong>: <a href="tel:+447464385589">+44 7464 385589</a></li>
                   <li><strong>Our Location:</strong> Avolonos 1, Maria House 1075, Nicosia, Cyprus</li>
                    <li>Last updated: May 25, 2024 at 12:00 pm</li>
                     
                     </ul>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>