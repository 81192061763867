<template>
    <div class="account-register-area ptb-100">
        <div class="container">
            <div class="account-register-content">
                <h2>Start Trading on PayBuyGo</h2>
                <p>At PayBuyGo, we ensure a seamless user experience with a clean and intuitive interface, step-by-step guidance, and comprehensive support. From account creation to transactions, our platform is designed for ease of use, making buying cryptocurrencies simple and accessible for everyone. We are awaiting for you to give it a try on the fastest, easiest, and most reliable onramp solution on PayBuyGo.</p>
                <router-link to="/xx" class="default-btn">
<i class="bx bx-paper-plane"></i>                    Buy BTC via Credit Card
                </router-link>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape10"><img src="../../assets/img/shape/shape10.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTrading'
}
</script>