<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container"  style="margin: auto 50px;">
                <router-link class="navbar-brand" to="/">
                    <img class="kertole" src="../../assets/img/logo.svg" alt="logo">
                    
                    
                </router-link>
                <div
                    class="navbar-toggler" 
                    @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                    v-bind:class="{ 'active': button_active_state }"
                    v-on:click="button_active_state = !button_active_state"
                >
                    <i class="fas fa-bars"></i>
                    <i class="fas fa-times"></i>
                </div>
                <div class="collapse navbar-collapse" :class="{ toggler: active }">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link">
                                Home
                            </router-link>
                        </li>
                        <li class="nav-item megamenu">
                            <a href="#" class="dropdown-toggle nav-link">Buy</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/buy" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency2.png" alt="image">
                                        BTC - Bitcoin
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/buy" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/tether.png" alt="image">
                                        USDT - Tether
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/buy" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency4.png" alt="image">
                                        XRP - Ripple
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/buy" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency5.png" alt="image">
                                        STE - Stellar
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/cryptocurrency" class="nav-link">
                                        <i class='bx bxs-chevron-right-circle'></i>
                                        View All Coins
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    <!---    <li class="nav-item megamenu">
                            <a href="#" class="dropdown-toggle nav-link">Sell</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/sell" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency4.png" alt="image">
                                        XRP - Ripple
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/sell" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency5.png" alt="image">
                                        STE - Stellar
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/sell" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency2.png" alt="image">
                                        BTC - Bitcoin
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/sell" class="nav-link">
                                        <img src="../../assets/img/cryptocurrency/cryptocurrency3.png" alt="image">
                                        MIT - Litecoin
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/cryptocurrency" class="nav-link">
                                        <i class='bx bxs-chevron-right-circle'></i>
                                        View All Coins
                                    </router-link>
                                </li>
                            </ul>
                        </li> --->
                        <li class="nav-item">
                            <router-link to="/prices" class="nav-link">
                                Listings
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/faq" class="nav-link">
                                Support
                            </router-link>
                        </li>
                         <li class="nav-item">
                            <router-link to="/about" class="nav-link">
                                About Us
                            </router-link>
                        </li>
                        
                       
                       
                    </ul>
                </div>

                <div class="others-option">
                    <div class="d-flex align-items-center">
                      <!---  <div class="option-item">
                            <router-link to="/login-register" class="login-btn">
                                <i class='bx bx-log-in'></i> 
                                Login
                            </router-link>
                        </div> --->
                        <div class="option-item triel">
                            <router-link to="/contact" class="btn">
                                <b>Contact Us</b>
                            </router-link>
                        </div>
                       <div class="option-item triel">
                            <router-link to="/about" class="default-btn">
                                Buy Crypto Now
                                                                <i class='bx bx-paper-plane'></i> 

                            </router-link>
                        </div> 
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<style>
                        @media only screen and (max-width: 600px) {
  .triel {
    display: none;
  }
}
                        </style>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
}
</script>