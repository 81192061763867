<template>
    <div class="account-register-area ptb-100">
        <div class="container">
            <div class="account-register-content">
                <h2>Start Trading on PayBuyGo</h2>
                <p>asdasd</p>
                <router-link to="/login-register" class="default-btn">
                    <i class='bx bxs-user'></i> 
Pay via Credit Card                </router-link>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTrading'
}
</script>