<template>
    <div class="buy-sell-cryptocurrency-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>How to Buy Cryptocurrency</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-buy-sell-item">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon12.png" alt="image">
                        </div>
                        <h3>Bank Transfers</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur elit.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-buy-sell-item">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon13.png" alt="image">
                        </div>
                        <h3>Online Wallets</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur elit.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-buy-sell-item">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon14.png" alt="image">
                        </div>
                        <h3>Cash Payment</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur elit.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-buy-sell-item">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon15.png" alt="image">
                        </div>
                        <h3>Debit/Credit Cards</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur elit.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-buy-sell-item">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon16.png" alt="image">
                        </div>
                        <h3>Discounted Gift Cards</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur elit.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-buy-sell-item">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon17.png" alt="image">
                        </div>
                        <h3>Goods & Services</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet, consectetur elit.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape13"><img src="../../assets/img/shape/shape13.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'BuyAndSell'
}
</script>