<template>
    <div class="wallet-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="wallet-content">
                        <h2>Be Your Own Bank</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        <router-link to="/login-register" class="default-btn">
                            <i class='bx bxs-user'></i> 
                            Create Your Wallet
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="wallet-image text-center">
                        <img src="../../assets/img/wallet.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YourOwnBank'
}
</script>